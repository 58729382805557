import { rtlLanguages } from '../static/staticData';

export const getPrototypeLink = (link: string) => {
  if (link.includes('figma.com/embed')) {
    return link;
  }

  if (link.includes('xd.adobe.com/embed')) {
    return link;
  }

  if (link.includes('figma.com')) {
    return `https://www.figma.com/embed?embed_host=share&url=${link}`;
  }

  if (link.includes('xd.adobe.com/view')) {
    return link.replace('xd.adobe.com/view', 'xd.adobe.com/embed');
  }

  if (!link.startsWith('http')) {
    return `http://${link}`;
  }

  return link;
};

export const isValidImageURL = (str: string) => {
  if (typeof str !== 'string') return false;
  return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
};

export const isMobile = () => {
  return /mobile|tablet|android|ipad|iphone/i.test(navigator.userAgent.toLowerCase());
};

export const isRtl = (lang: string) => {
  return rtlLanguages.includes(lang);
};

export const validateDataField = (str: string, validations: any, is_mandatory: boolean) => {
  if (str.trim().length === 0) {
    return {
      goto_next: !is_mandatory,
      message: 'Enter valid input',
    };
  }
  const str_length = str.trim().length;
  if (validations && validations.field_type) {
    if (validations.field_type === 'number') {
      const regrexForNumber = /^\d+$/;
      if (!regrexForNumber.test(str.trim())) {
        return {
          goto_next: false,
          message: `Please enter a ${validations.min_length}-digit number`,
        };
      }
      if (validations.min_length > 0 && validations.min_length > str_length) {
        return {
          goto_next: false,
          message: `Please enter a ${validations.min_length}-digit number`,
        };
      }
      if (validations.max_length && validations.max_length < str_length) {
        return {
          goto_next: false,
          message: `Please enter a number with ${validations.min_length}-${validations.max_length} digits`,
        };
      }
    }
    if (validations.field_type === 'email') {
      const regexForEmail =
        /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      if (!regexForEmail.test(str)) {
        return {
          goto_next: false,
          message: 'Please enter a valid email Id',
        };
      }
    }
    if (validations.min_length > 0 && validations.min_length > str_length) {
      return {
        goto_next: false,
        message: `Please enter a ${validations.min_length}-character text`,
      };
    }
    if (validations.max_length && validations.max_length < str_length) {
      return {
        goto_next: false,
        message: `Please enter a text with ${validations.min_length}-${validations.max_length} characters`,
      };
    }
  }
  return { goto_next: true, message: '' };
};
