export const previewTheme = [
  {
    value: 'white',
    label: 'White',
    background: '#FFFFFF',
    textColor: '#000000',
    optionColor: '#4C69FE',
    optionBackground: '#FFFFFF',
    borderColor: '#E8E8E8',
    ctaBackground: '#4C69FE',
    ctaText: '#FFFFFF',
  },
  {
    value: 'summer',
    label: 'Summer',
    background: '#FBF4E7',
    textColor: '#1C0A73',
    optionColor: '#1C0A73',
    optionBackground: '#FFFFFF',
    borderColor: '#E8E8E8',
    ctaBackground: '#1C0A73',
    ctaText: '#FFFFFF',
  },
  {
    value: 'violet',
    label: 'Violet',
    background: '#FCF9FF',
    textColor: '#000000',
    optionColor: '#7A4CFE',
    optionBackground: '#FFFFFF',
    borderColor: '#E8E8E8',
    ctaBackground: '#655BDE',
    ctaText: '#FFFFFF',
  },
  {
    value: 'black',
    label: 'Black',
    background: '#0F0F0F',
    textColor: '#FFFFFF',
    optionColor: '#A8BBFF',
    optionBackground: '#0A0A0A',
    borderColor: '#3B3B3B',
    ctaBackground: '#FFFFFF',
    ctaText: '#000000',
  },
  {
    value: 'purpleberry',
    label: 'Purple Berry',
    background: '#251A2D',
    textColor: '#FFFFFF',
    optionColor: '#FFAFC2',
    optionBackground: '#281D31',
    borderColor: '#574162',
    ctaBackground: '#FFFFFF',
    ctaText: '#000000',
  },
  {
    value: 'moonlight',
    label: 'Moonlight',
    background: '#212338',
    textColor: '#FFFFFF',
    optionColor: '#BAAFFF',
    optionBackground: '#212237',
    borderColor: '#35374A',
    ctaBackground: '#FFFFFF',
    ctaText: '#212338',
  },
];

export const emojisForFeedback = [
  {
    value: 1,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-1.png',
  },
  {
    value: 2,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-2.png',
  },
  {
    value: 3,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-3.png',
  },
  {
    value: 4,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-4.png',
  },
  {
    value: 5,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-5.png',
  },
];

export const shortRatingQuestions = ['scale_feedback', 'star_feedback', 'emoji_feedback'];
export const allRatingQuestions = [...shortRatingQuestions, 'nps_feedback'];

export const rtlLanguages = ['ar', 'ur', 'fa', 'he'];
