import React, { useEffect, useState } from 'react';
import {
  ImageBanner,
  MainContainer,
  QuizMainBox,
  QuizTitle,
  QuizSubTitle,
  AnswerText,
  AnswerBox,
  QuestionText,
  AnswerOptions,
  AnswerExplanation,
} from './QuizStyled';
import { useParams } from 'react-router-dom';
import { IQuizResult, Iparams } from '../../types/survey';
import { fetchQuizResults } from '../../services/linkService';
import { ReactComponent as CorrectImg } from '../../images/correct.svg';
import { ReactComponent as WrongImg } from '../../images/wrong.svg';
import { Oval } from 'react-loader-spinner';

export const ResultComponent = ({ theme }) => {
  const params: Iparams = useParams();
  const { link } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [quizResult, setQuizResult] = useState<IQuizResult>();
  const surveyTheme = theme;

  const getResults = async () => {
    const results = await fetchQuizResults(link);
    if (results && results.data) {
      setQuizResult(results.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getResults();
  }, [link]);

  if (isLoading || !quizResult) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: surveyTheme && surveyTheme.bg_color ? surveyTheme.bg_color : 'black',
        }}
      >
        <Oval
          ariaLabel="loading-indicator"
          height={40}
          width={40}
          strokeWidth={5}
          strokeWidthSecondary={5}
          color={surveyTheme && surveyTheme.highlight_color ? surveyTheme.highlight_color : 'black'}
          secondaryColor={surveyTheme && surveyTheme.text_color ? `${surveyTheme.text_color}50` : 'grey'}
          wrapperStyle={{ marginTop: '45vh' }}
        />
      </div>
    );
  } else {
    const { question_arr, user_score, result_page } = quizResult;
    const { page_title = '', page_sub_title = '', banner_img, display_answers } = result_page;
    const modified_page_title = page_title.replaceAll('##score##', user_score.toString());

    const bg_color = surveyTheme && surveyTheme.bg_color ? surveyTheme.bg_color : '';
    const text_color = surveyTheme && surveyTheme.text_color ? surveyTheme.text_color : '';

    return (
      <MainContainer style={{ backgroundColor: `${bg_color}` }}>
        <ImageBanner src={banner_img}></ImageBanner>
        <QuizMainBox>
          <QuizTitle style={{ color: `${text_color}` }}>{modified_page_title}</QuizTitle>
          <QuizSubTitle style={{ color: `${text_color}` }}>{page_sub_title}</QuizSubTitle>
          {display_answers && (
            <>
              <AnswerText>Answers</AnswerText>

              {question_arr.map(({ question_text, response, quiz_explanation, is_user_correct }, idx) => (
                <AnswerBox>
                  <QuestionText style={{ color: `${text_color}` }}>{question_text}</QuestionText>
                  {response.map((r) => (
                    <AnswerOptions style={{ color: `${is_user_correct ? '#1ea408' : '#ff4b56'}` }}>
                      {is_user_correct ? <CorrectImg /> : <WrongImg />} {r.option_text}
                    </AnswerOptions>
                  ))}
                  <AnswerExplanation style={{ color: `${text_color}60` }}>{quiz_explanation}</AnswerExplanation>
                </AnswerBox>
              ))}
            </>
          )}
        </QuizMainBox>
      </MainContainer>
    );
  }
};
