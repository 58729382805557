import React from 'react';
import { MainContainer } from './StyledUtils';
import { ReactComponent as ErrorImg } from '../images/error-route.svg';

export default function ErrorComponent() {
  return (
    <MainContainer>
      <ErrorImg />
    </MainContainer>
  );
}
