import React from 'react';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const FormContainer = styled.div`
  padding: 0;
  padding-top: 2rem;
  width: 65vw;
  height: fit-content;
  @media (max-width: 600px) {
    width: 100%;
    padding: 1.87rem;
    padding-bottom: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  column-gap: 15px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 0;
  }
`;

export const QuestionImgContainer = styled.div`
     display: flex,
     align-items: center;
     justify-content: center;
    padding-bottom: 14px;
    @media (max-width: 600px) {
      padding-bottom:10px;
    }
`;

export const QuestionText = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.9rem;
  margin-bottom: 0.7rem;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 1.25rem;
    line-height: 1.47rem;
    padding-top: 1rem;
  }
`;

export const QuestionDescription = styled.h2`
  font-size: 1.375rem;
  line-height: 1.8rem;
  margin-bottom: 3.1rem;
  text-align: left;
  font-weight: 400;
  @media (max-width: 600px) {
    font-size: 0.87rem;
    line-height: 1.1rem;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-bottom: 8px;
  cursor: pointer;
  padding-block: 1rem;
  padding-inline: 12px;
  @media (max-width: 600px) {
    padding-block: 0.68rem;
    padding-inline: 12px;
  }
`;

export const OptionText = styled.h2`
  padding-inline: 6px;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 400;
  @media (max-width: 600px) {
    font-size: 0.93rem;
    line-height: 1.2rem;
  }
`;

export const ButtonContainer = styled.div`
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const DemoButton = styled.button`
  border-radius: 5px;
  margin-top: 3.4rem;
  border: 0;
  padding: 0.9rem 2.7rem;
  cursor: pointer;
  @media (max-width: 600px) {
    text-align: center;
    padding: 0.7rem 2.1rem;
    margin-top: 4rem;
  }
`;

export const ButtonText = styled.h2`
  font-size: 1.375rem;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: 1rem;
    line-height: 1.3rem;
  }
`;

export const FooterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
  @media (max-width: 600px) {
    padding-top: 1.5rem;
  }
`;

export const BrandText = styled.h2`
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.5;
  @media (max-width: 600px) {
    font-size: 0.6rem;
  }
`;

export const BrandNameText = styled.h2`
  font-size: 0.9rem;
  font-weight: 700;
  opacity: 0.5;
  margin-left: 3px;
  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 100%;
`;

export const ToggleButton = styled.div`
  font-weight: 700;
  cursor: pointer;
  padding-block: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 600px) {
    padding-block: 8px;
    margin-right: 4px;
  }
`;

export const NpsTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const NpsOption = styled.h4`
  font-size: 1.875rem;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  @media (max-width: 600px) {
    font-size: 0.87rem;
  }
`;

export const NpsText = styled.p`
  font-size: 1rem;
  opacity: 0.5;
  margin-block-start: 0px;
  margin-block-end: 0px;
  width: 22%;
  word-break: break-word;
  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const TextInput = styled.textarea`
  outline: 0;
  border-width: 0 0 2px;
  border-color: blue;
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  font-size: 1.3rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const ProgressbarPath = styled.div`
  height: 5px;
  border-radius: 100px;
  margin-bottom: 40px;
  width: 254px;
  @media (max-width: 600px) {
    width: 131px;
    margin-bottom: 30px;
  }
`;

export const InnerProgressbar = styled.div`
  height: 5px;
  border-radius: 100px;
`;

export const CompleteBox = styled.div`
  border: 1px solid #26479e26;
  border-radius: 20px;
  padding-block: 2rem;
  padding-inline: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 450px;
  @media (max-width: 600px) {
    width: unset;
    height: unset;
    margin-inline: 1rem;
  }
`;

export const CompleteTitle = styled.h2`
  font-size: 1.6rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
`;

export const CompleteSubTitle = styled.p`
  font-size: 1.25rem;
`;

export const ErrorText = styled.p`
  font-size: 0.8rem;
  color: #ff244c;
  margin-top: 0.5rem;
`;
