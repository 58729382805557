import React, { useState } from 'react';
import { recordResponseByLink } from '../services/linkService';
import { shortRatingQuestions } from '../static/staticData';

export const usePrefillAnswer = ({ surveyData, setIsLoading, prefill_answers, nextSurveyScreen }) => {
  const [pendingResponses, setPendingResponses] = useState<Array<any>>([]);

  const processPrefillQueue = async (link: string, user_id: string) => {
    if (!surveyData || pendingResponses.length === 0) return;

    const { auth_token } = surveyData;

    for (const i in pendingResponses) {
      const response_item = pendingResponses[i];
      await recordResponseByLink(response_item, link, auth_token, user_id);
    }

    setPendingResponses([]);
  };

  const handlePrefillAnswer = async (question_order: number) => {
    if (!surveyData) return null;

    setIsLoading(true);

    const { questions } = surveyData;
    const question = questions[question_order - 1];

    const key = `q${question_order}`;
    const prefill_value = prefill_answers[key];

    if (prefill_value && question) {
      const { question_id, question_type, rule_sets, question_order, properties, is_mandatory } = question;
      if (
        shortRatingQuestions.includes(question_type) &&
        +prefill_value >= 1 &&
        +prefill_value <= 5 &&
        +prefill_value % 1 === 0
      ) {
        await nextSurveyScreen(question_id, question_order, question_type, rule_sets, '', +prefill_value, [], true);
      } else if (
        question_type === 'nps_feedback' &&
        +prefill_value >= 0 &&
        +prefill_value <= 10 &&
        +prefill_value % 1 === 0
      ) {
        await nextSurveyScreen(question_id, question_order, question_type, rule_sets, '', +prefill_value, [], true);
      } else if (
        question_type === 'input_feedback' ||
        question_type === 'intro_prompt' ||
        question_type === 'data_collection'
      ) {
        if ((is_mandatory && prefill_value) || !is_mandatory) {
          await nextSurveyScreen(question_id, question_order, question_type, rule_sets, prefill_value, 0, [], true);
        }
      } else if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
        if (question_type === 'single_select_feedback' && prefill_value) {
          const { options } = properties;

          for (const i in options) {
            const { option_text } = options[i];
            if (option_text === prefill_value) {
              await nextSurveyScreen(question_id, question_order, question_type, rule_sets, '', 0, [options[i]], true);
            }
          }
        }

        const prefill_options: any = [];

        let prefill_value_array = prefill_value.split(',') || [];
        prefill_value_array = prefill_value_array.map((v) => v.toLowerCase());
        const { options } = properties;

        for (const i in options) {
          const { option_text } = options[i];
          if (prefill_value_array.includes(option_text.toLowerCase())) {
            prefill_options.push(options[i]);
          }
        }

        if (
          question_type === 'multi_select_feedback' &&
          ((is_mandatory && prefill_options.length > 0) || !is_mandatory)
        ) {
          await nextSurveyScreen(question_id, question_order, question_type, rule_sets, '', 0, prefill_options, true);
        }
      }
    }

    if (!prefill_answers[`q${question_order + 1}`]) {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  return { processPrefillQueue, handlePrefillAnswer, pendingResponses, setPendingResponses };
};
