/* eslint-disable @typescript-eslint/ban-types */
import amplitude from 'amplitude-js';

const amplitude_api_key = 'd8bd491671aa530c2b9b4419ae595792';

export const amplitudeEvent = (member_id: string, event_name: string, event_properties: Object) => {
  if (window && window.location && window.location.href && window.location.href.includes('https://link')) {
    // amplitude.getInstance().init(amplitude_api_key, member_id);
    //  amplitude.getInstance().logEvent(event_name, event_properties);
  }
};
