import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import QuestionPage from './pages/SurveyPage';
import ErrorPage from './pages/ErrorPage';
import ErrorRoutePage from './pages/ErrorRoutePage';
import ThanksPage from './pages/ThanksPage';
import CompletePage from './pages/CompletePage';
import PrototypePage from './pages/prototype/PrototypePage';

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Switch>
              <Route path="/error" exact={true} component={ErrorPage} />
              <Route path="/complete" exact={true} component={CompletePage} />
              <Route path="/thanks" exact={true} component={ThanksPage} />
              <Route path="/:link" exact={true} component={QuestionPage} />
              <Route path="/:link/prototype" exact={true} component={PrototypePage} />
              <Route path="/:link/quiz" exact={true} component={QuestionPage} />
              <Route path="*" component={ErrorRoutePage}></Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
