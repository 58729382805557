import React from 'react';
import { MainContainer } from './StyledUtils';
import { ReactComponent as ErrorRouteImg } from '../images/error-survey.svg';

export default function ErrorRouteComponent() {
  return (
    <MainContainer>
      <ErrorRouteImg />
    </MainContainer>
  );
}
