import React from 'react';
import { CompleteBox, CompleteSubTitle, CompleteTitle, MainContainer } from './StyledUtils';
import { ReactComponent as CompleteImg } from '../images/complete-img.svg';

export default function CompleteHiiComponent() {
  return (
    <MainContainer>
      <CompleteBox>
        <CompleteImg />
        <CompleteTitle>Form already submitted</CompleteTitle>
        <CompleteSubTitle>Looks like you’ve previously filled and submitted a response to this form.</CompleteSubTitle>
      </CompleteBox>
    </MainContainer>
  );
}
