import axios from 'axios';
import CONFIG from './config';

export const getCall = async (path_url: string) => {
  return axios
    .get(CONFIG.api_endpoint + path_url, {})
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 400) {
        localStorage.clear();
        window.location.href = '/error';
      }
    });
};

export const postCall = async (path_url: string, payload: any, auth_token: string) => {
  return axios
    .post(CONFIG.api_endpoint + path_url, payload, {
      headers: { 'auth-token': auth_token },
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
};

export const putCall = async (path_url: string, payload: any, auth_token: string) => {
  return axios
    .put(CONFIG.api_endpoint + path_url, payload, {
      headers: { 'auth-token': auth_token },
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
};
