import React from 'react';
import styled from 'styled-components';

export const MainContainer = styled.div`
  min-height: 100vh;
`;

export const ImageBanner = styled.img`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const QuizMainBox = styled.div`
  margin-top: 15px;
  @media (max-width: 600px) {
    padding: 16px;
  }
`;

export const QuizTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const QuizSubTitle = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const AnswerText = styled.p`
  font-size: 14px;
  margin-bottom: 7px;
  color: #5e5e5e;
`;

export const AnswerBox = styled.div`
  padding: 20px 16px;
  border-radius: 3px;
  border: solid 1px rgba(255, 255, 255, 0.17);
  background-color: rgba(0, 0, 0, 0.03);
`;

export const QuestionText = styled.h5`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 13px;
`;

export const AnswerOptions = styled.h6`
  color: #ff4b56;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const AnswerExplanation = styled.p`
  color: #5e5e5e;
  font-size: 14px;
`;
