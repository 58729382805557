import _ from 'lodash';
import { allRatingQuestions } from '../static/staticData';

export const checkRange = (question_type: string, value: number) => {
  if (value === null) return false;

  if (question_type === 'nps_feedback' && value >= 0 && value <= 10) {
    return true;
  }
  if (
    (question_type === 'scale_feedback' || question_type === 'star_feedback' || question_type === 'emoji_feedback') &&
    value >= 1 &&
    value <= 5
  ) {
    return true;
  }
  return false;
};

export const replacePlaceholderText = (placeholder_values = {}, question_text: string) => {
  if (placeholder_values && !_.isEmpty(placeholder_values)) {
    for (const i in placeholder_values) {
      const value = placeholder_values[i] ? placeholder_values[i] : '';
      if (question_text.includes(`#${i}#`)) {
        question_text = question_text.replaceAll(`#${i}#`, value);
      }
    }
  }
  return question_text;
};

export const formatResponseData = (
  question_type: string,
  survey_input: string | undefined,
  scale_value: number | undefined,
  responses: Array<any> | undefined,
) => {
  let response;
  let field_name = '';
  if (question_type === 'input_feedback' || question_type === 'data_collection') {
    field_name = 'survey_input';
    response = survey_input ? survey_input : '';
  } else if (allRatingQuestions.includes(question_type) && scale_value !== undefined && scale_value >= 0) {
    field_name = 'scale_value';
    response = scale_value;
  } else if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
    field_name = 'responses';
    response = responses ? responses : [];
  } else if (question_type === 'intro_prompt') {
    field_name = 'intro_prompt';
    response = '';
  }

  return { response, field_name };
};
