import React, { useEffect, useState } from 'react';
import { QuestionDescription, QuestionText, FooterBox, BrandText, BrandNameText, MainContainer } from './StyledUtils';
import { Oval } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';

export default function ThanksCardComponent() {
  const [isLoading, setIsLoading] = useState(true);
  const [thanksState, setThanksState] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
    const pageState: any = location.state;
    if (pageState) {
      const { thanksCard, theme, showLogo, surveyRedirectLink = '' } = pageState;
      setThanksState({ thanksCard, theme, showLogo, surveyRedirectLink });

      if (surveyRedirectLink) {
        setTimeout(() => {
          window.location.href = surveyRedirectLink;
        }, 2500);
      }
    }

    setIsLoading(false);
  }, []);

  const { thanksCard, theme, showLogo } = thanksState;

  if (!thanksCard || !theme) {
    return null;
  }

  const { thanks_card_desc, thanks_card_title, show_thanks_card } = thanksCard;
  const survey_theme = theme;

  if (isLoading) {
    return (
      <MainContainer style={{ backgroundColor: `${survey_theme.bg_color}` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: survey_theme && survey_theme.bg_color ? survey_theme.bg_color : 'black',
          }}
        >
          <Oval
            ariaLabel="loading-indicator"
            height={40}
            width={40}
            strokeWidth={5}
            strokeWidthSecondary={5}
            color={survey_theme && survey_theme.highlight_color ? survey_theme.highlight_color : 'black'}
            secondaryColor={survey_theme && survey_theme.text_color ? `${survey_theme.text_color}50` : 'grey'}
            wrapperStyle={{ marginTop: '45vh' }}
          />
        </div>
      </MainContainer>
    );
  } else {
    return (
      <MainContainer style={{ backgroundColor: `${survey_theme.bg_color}` }}>
        {show_thanks_card ? (
          <div style={{ textAlign: 'center' }}>
            <div style={{ height: 56 }}>
              <img src={'https://cdn.blitzllama.com/survey/assets/green-tick.svg'} alt="" style={{ width: 56 }} />
            </div>
            <QuestionText
              style={{
                wordWrap: 'break-word',
                textAlign: 'center',
                color: `${theme?.text_color}`,
                paddingTop: '1rem',
              }}
            >
              {thanks_card_title}
            </QuestionText>
            <QuestionDescription
              style={{
                marginTop: 8,
                textAlign: 'center',
                marginBottom: '3.75rem',
                color: `${theme?.text_color}80`,
              }}
            >
              {thanks_card_desc}
            </QuestionDescription>

            <FooterBox>
              {showLogo && (
                <>
                  <BrandText style={{ color: `${theme?.text_color}` }}>powered by </BrandText>{' '}
                  <BrandNameText style={{ color: `${theme?.text_color}` }}>Blitzllama</BrandNameText>
                </>
              )}
            </FooterBox>
          </div>
        ) : (
          <h4
            style={{
              fontSize: '1.375rem',
              fontWeight: 700,
              marginTop: 10,
              textAlign: 'center',
              color: `${theme?.text_color}`,
            }}
          >
            The survey has ended.
          </h4>
        )}
      </MainContainer>
    );
  }
}
