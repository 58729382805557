import { amplitudeEvent } from '../utils/amplitude.util';
import { postCall, putCall, getCall } from '../apiCall';
import { ISurvey } from '../types/survey';
import { FETCH_SURVEY, RECORD_RESPONSE, EDIT_SURVEY_STATUS, QUIZ_RESULTS } from './apiEndPoints';

export const customEvents = async (user_id: string, event_name: string, properties = {}) => {
  const auth_token1 = localStorage.getItem('auth_token') || '';
  const rand = user_id ? user_id : new Date().getTime().toString();
  amplitudeEvent(rand, event_name, { t: new Date().toISOString(), auth_token1, ...properties });
};

export const fetchSurveyByLink = async (link: string, lang_code: string, user_id: string, preview = '') => {
  const auth_token1 = localStorage.getItem('auth_token') || '';
  const rand = user_id ? user_id : new Date().getTime().toString();
  amplitudeEvent(rand, 'fetchSurveyByLink', { link, t: new Date().toISOString(), auth_token1 });

  return new Promise<ISurvey>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY(link, lang_code, user_id, preview))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const recordResponseByLink = async (payload: any, link: string, auth_token: string, user_id: string) => {
  const auth_token1 = localStorage.getItem('auth_token') || '';

  const rand = user_id ? user_id : new Date().getTime().toString();
  amplitudeEvent(rand, 'recordResponseByLink', { link, t: new Date().toISOString(), auth_token1 });

  return new Promise<any>((resolve, reject) => {
    try {
      postCall(RECORD_RESPONSE(link), payload, auth_token)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveyStatus = async (survey_id: string, auth_token: string, user_id: string) => {
  const auth_token1 = localStorage.getItem('auth_token') || '';

  const rand = user_id || new Date().getTime().toString();
  amplitudeEvent(rand, 'editSurveyStatus', { t: new Date().toISOString(), auth_token1 });

  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_STATUS(survey_id), {}, auth_token)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchQuizResults = async (link: string) => {
  const auth_token1 = localStorage.getItem('auth_token') || '';

  return new Promise<any>((resolve, reject) => {
    try {
      postCall(QUIZ_RESULTS(link), {}, auth_token1)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
