import React from 'react';
import styled from 'styled-components';
import {
  ButtonContainer,
  ButtonText,
  DemoButton,
  FormContainer,
  MainContainer,
  NpsOption,
  NpsText,
  OptionText,
  ProgressbarPath,
  QuestionDescription,
  QuestionText,
  SelectOption,
  TextInput,
  ToggleButton,
} from '../StyledUtils';

export const PrototypeContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: unset;
  }
`;

export const PrototypeFormContainer: any = styled(FormContainer)`
  padding-inline: ${(props: any) => (props.is_full ? '' : '35px')};
`;

export const PrototypeQuestionText: any = styled(QuestionText)`
  font-size: ${(props: any) => (props.is_full ? '' : '1.25rem')};
  line-height: ${(props: any) => (props.is_full ? '' : '1.5rem')};
  margin-bottom: ${(props: any) => (props.is_full ? '' : '0.4rem')};
`;

export const PrototypeQuestionDescription: any = styled(QuestionDescription)`
  font-size: ${(props: any) => (props.is_full ? '' : '0.9rem')};
  line-height: ${(props: any) => (props.is_full ? '' : '1rem')};
  margin-bottom: ${(props: any) => (props.is_full ? '' : '2.5rem')};
`;

export const PrototypeSelectOption: any = styled(SelectOption)`
  padding-block: ${(props: any) => (props.is_full ? '' : '0.6rem')};
  padding-left: ${(props: any) => (props.is_full ? '' : '8px')};
`;

export const PrototypeOptionText: any = styled(OptionText)`
  font-size: ${(props: any) => (props.is_full ? '' : '1rem')};
`;

export const PrototypeButtonContainer: any = styled(ButtonContainer)`
  text-align: ${(props: any) => (props.is_full ? '' : 'center')}; ;
`;

export const PrototypeDemoButton: any = styled(DemoButton)`
  padding: ${(props: any) => (props.is_full ? '' : '0.8rem 2.2rem')};
`;

export const PrototypeButtonText: any = styled(ButtonText)`
  font-size: ${(props: any) => (props.is_full ? '' : '1rem')};
`;

export const PrototypeToggleButton: any = styled(ToggleButton)`
  padding-block: ${(props: any) => (props.is_full ? '' : '10px')};
  margin-right: ${(props: any) => (props.is_full ? '' : '5px')};
`;

export const PrototypeNpsOption: any = styled(NpsOption)`
  font-size: ${(props: any) => (props.is_full ? '' : '1.25rem')};
`;

export const PrototypeNpsText: any = styled(NpsText)`
  font-size: ${(props: any) => (props.is_full ? '' : '0.8rem')};
`;

export const PrototypeTextInput: any = styled(TextInput)`
  font-size: ${(props: any) => (props.is_full ? '' : '1rem')};
`;

export const PrototypeProgressbarPath: any = styled(ProgressbarPath)`
  margin-bottom: ${(props: any) => (props.is_full ? '' : '2.3rem')};
  width: ${(props: any) => (props.is_full ? '' : '165px')};
`;

export const QuestionVisibilityButton = styled.button`
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  @media (max-width: 600px) {
    top: unset;
    right: unset;
    height: 36px;
    background-color: none;
    bottom: 25px;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: fit-content;
  }
`;

export const ToggleButtonText = styled.p`
  font-size: 16px;
  margin-left: 0.3rem;
`;
